import styled from "styled-components";

export const AuthPage = styled.div`

.css-11yukd5-MuiTabs-indicator{
    background-color:unset !important;
}
.background{
    background-color:red
}

.container{


.header{
    background-color: white !important;
color:blue !important;
border-bottom: 1px solid #efecec !important;
align-items: end !important;
box-shadow:unset !important;
justify-content: space-between !important;
display:flex !imprtant;
flex-direction: unset !important;
-webkit-flex-direction: unset !important;

}
}
}
`;
