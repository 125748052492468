import React from "react";

const Dashboard = () => {

  return (
    <>
      <p>DashboardDashboard</p>
    </>
  );
}

export default Dashboard;